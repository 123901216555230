<template>
  <div>
    <div class="head">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#fff"
        text-color="#486382"
        active-text-color="#486382"
      >
        <el-menu-item index="1">每日一考</el-menu-item>
        <!-- <el-menu-item index="2">学员成绩</el-menu-item> -->
      </el-menu>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name:"MRYKLive",  
  data() {
    return {};
  },
  computed: {
    activeIndex() {
      let index = this.$store.state.activeList.split("-")[1];
      return "" + index;
    },
  },
  methods: {
    handleSelect(key) {
      switch (Number(key)) {
        case 1:
          this.$router.push({ name: "TestCase" });
          break;
        case 2:
          this.$router.push({ name: "StudentScores" });
          break;
        default:
          console.log("aaa");
          break;
      }
    },
  },
};
</script>
